import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'DM Sans', sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'DM Sans', sans-serif;
    margin-top: 0;
  }
  p{
    font-family: 'DM Sans', sans-serif;
  }

  section {
    position: relative;
  }
  
`;

export const ContentWrapper = styled.div`
	overflow: hidden;
	.menuLeft {
		margin-left: 105px;
	}
	.menuRight {
		margin-left: auto;
	}

	.sticky-nav-active {
		.agencyFormal-navbar {
			background-color: #fff;
			box-shadow: 0px 3px 8px 0px rgba(43, 83, 135, 0.08);
		}
	}
`;
